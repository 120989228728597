<template>
    <div class="community overflow-y-auto" v-if="tagInfo" @scroll="handleScroll">

        <div class="headBar">
            <div class="leftIcon" @click="$router.go(-1)">
                <img class="navArrow" src="@/assets/png/leftH.png" />
            </div>
<!--            <div class="headTitle">-->
<!--                <span>{{ tagInfo.name }}</span>-->
<!--            </div>-->
<!--            <div class="focusBtn" v-if="!tagInfo.hasCollected" @click="focus(tagInfo)">-->
<!--                <svg-icon icon-class="btnAddWhite" class="btnIcon"></svg-icon>-->
<!--                <span>关注</span>-->
<!--            </div>-->
<!--            <div class="alreadyFocus" v-else @click="focus(tagInfo)">-->
<!--                <span>已关注</span>-->
<!--            </div>-->
        </div>

        <div class="headBg">
            <ImgDecypt v-if="tagInfo.coverImg" :src="tagInfo.coverImg" :key="imgKeys" />
        </div>

        <div class="tagContent flex-between">
            <div class="tagBox">
             <div class="tagInfo">
              <div class="tagName">
               <span>#{{tagInfo.name}}</span>
              </div>
             </div>
             <div class="tagDesc">
                <span>
                    {{tagInfo.tagDesc}}
                </span>
             </div>
            </div>
            <div class="followBox">
             <div class="focusBtn" v-if="!tagInfo.hasCollected" @click="focus(tagInfo)">
              <img src="@/assets/png/communityFollow.png">
             </div>
             <div class="alreadyFocus" v-else @click="focus(tagInfo)">
              <img src="@/assets/png/noCommunityFollow.png">
             </div>
            </div>
        </div>

        <div class="hotTopics" @touchmove.stop v-if="hotspotWonderList && hotspotWonderList.length">
         <div class="topicsHead">
          <div class="topicsTitle">
           <span>相关话题</span>
          </div>
          <div class="more" @click="moreTopic">
           <span>查看更多</span>
          </div>
         </div>
         <div class="topicsContent">
          <div class="topicsItem" v-for="(item,index) in hotspotWonderList" :key="index"
               @click="tagJump(item)">
           <div class="portrait">
            <ImgDecypt class="avatarImg" :src="item.coverImg"/>
           </div>
           <div class="topicsInfo">
            <div class="topicsInfoTitle">
             <span>{{ item.name }}</span>
            </div>
           </div>
          </div>
         </div>
        </div>

        <van-tabs class="tabs" type="card" swipeable background="rgba(0,0,0,0)" @change="tabChange" line-width="18" line-height="2" color="#94D6DA" title-inactive-color="#666666" title-active-color="#333333" v-model="tabActive" sticky>
            <van-tab title="最新" class="tabContent">
                <PullRefresh v-if="!rIsLoading" :loading="rLoading" :refreshing="rRefreshing" :finished="rFinished" @onLoad="onLoad"
                             @onRefresh="onRefresh" :isNoData="rIsNoData" :error="error"
                             :adv-list="advList" :hasAdvPagination="true" className="TopicDetails">
                    <div class="makeContent">
                        <!-- 帖子列表 -->
                        <div class="communityList">
                            <CommunityList :communityList="rCommunityList"></CommunityList>
                        </div>
                    </div>
                </PullRefresh>
                <Loading v-else />
            </van-tab>
            <van-tab title="最热" class="tabContent">
                <PullRefresh v-if="!rIsLoading" :loading="rLoading" :refreshing="rRefreshing" :finished="rFinished" @onLoad="onLoad"
                             @onRefresh="onRefresh" :isNoData="rIsNoData" :error="error"
                             :adv-list="advList" :hasAdvPagination="true" className="TopicDetails">
                    <div class="focusContent">
                        <div class="communityList">
                            <CommunityList :communityList="rCommunityList"></CommunityList>
                        </div>
                    </div>
                </PullRefresh>
                <Loading v-else />
            </van-tab>
        </van-tabs >

        <van-overlay :show="showPublishBtn" @click="showPublishBtn = false" :z-index="2001">
            <div class="releaseBtns" @click.stop>
             <div class="releaseBtnGroup">
              <div class="item" @click.stop="jumpVideo">
               <img src="@/assets/png/videoIcon.png">
              </div>
              <div class="item" @click.stop="jumpImg">
               <img src="@/assets/png/imgIcon.png">
              </div>
             </div>
<!--              <div class="releaseBtnGroup">-->
<!--                <div class="releaseBtnGroup">-->
<!--                    <router-link tag="div" to="/publishImg">-->
<!--                        <span>图片</span>-->
<!--                    </router-link>-->
<!--                    <div class="division"></div>-->
<!--                    <router-link tag="div" to="/publishVideo">-->
<!--                        <span>视频</span>-->
<!--                    </router-link>-->
                    <!-- <div @click="releasePage">
                        <span>图片</span>
                    </div>
                    <div class="division"></div>
                    <div @click="releasePage">
                        <span>视频</span>
                    </div> -->
<!--                </div>-->
            </div>
        </van-overlay>
<!--        发布按钮-->
        <div class="publishBtn" @click="showPublishBtn = !showPublishBtn" v-show="showPublish"></div>

<!--        <van-overlay class="publishPop" :show="showPublishBtn" @click="showPublishBtn = false" z-index="2001">-->
<!--            <div class="publishImg" @click="jumpImg"></div>-->
<!--            <div class="publishVideo" @click="jumpVideo"></div>-->
<!--        </van-overlay>-->
        <!--      <div class="collectBtn" v-else @click="cancelCollectionWorks">-->
        <!--          <img src="@/assets/png/hasCollectBtn.png">-->
        <!--      </div>-->
    </div>

</template>
<script>

import PullRefresh from "@/components/PullRefresh";
import Loading from "@/components/Loading";
import {
 collectTag,
 queryCommunityList,
 queryCommunityTagList, queryHotspotWonderList,
 queryTagInfo
} from "@/api/community";
import ImgDecypt from "@/components/ImgDecypt";

import CommunityList from "@/components/community/CommunityList";
import {AdType, getAdItem, jumpAdv} from "@/utils/getConfig";
import { Overlay } from 'vant';
import {careOrcancle} from "@/api/user";
import {mapGetters} from "vuex";
import {querySearchTagsVidList} from "@/api/video";

export default {
    name: "TopicDetails",
    components:{
        PullRefresh,
        Loading,
        ImgDecypt,
        CommunityList,
        [Overlay.name]: Overlay,
    },
    data(){
        return {
            gIsLoading: true,
            rIsLoading: true,
            gLoading: true,
            rLoading: true,
            gFinished: false,
            rFinished: false,
            gIsNoData:false,
            rIsNoData: false,
            error: false,
            advList: [],
            rPageNumber: 1,
            rPageSize: 12,
            rCommunityList: [],
            tabActive: 0,
            commentsShow: false,
            rRefreshing: false,
            hotspotWonderList:[],
            releaseShow: false,
            tagID: "",
            tagInfo: null,
            showPublishBtn: false,
            imgKeys: 1,
            timeout: null,
            showPublish: true,
        }
    },
    computed:{
        ...mapGetters({
            userInfo: 'userInfo',
            isVip: 'isVip'
        }),
    },
    mounted() {
        let query = this.$route.query;

        if (query){
            this.tagID = query.id;
            this.getTagInfo();
            this.getHotspotWonderList();
            this.getCommunityList('refresh');
        }

    },methods:{
        handleScroll(e) {
         this.showPublish = false;

         if (this.timeout) {
          clearTimeout(this.timeout);
         }

         this.timeout = setTimeout(() => {
          this.showPublish = true;
         }, 300);
        },
        tagJump(item) {
         /**
          * 话题详情
          */
         this.$router.replace({
          path: "/topicDetails",
          query: {
           id: item.id
          }
         })
        },
        async getTagInfo() {
            let req = {
                tagID: this.tagID
            }
            let res = await this.$Api(queryTagInfo, req);

            if(res && res.code == 200){

                this.$bus.$emit("dataBuriedEvent",{
                    "dataType":2,
                    tagsName: res.data.name
                });

                this.tagInfo = res.data;
                this.imgKeys+=1;
            }
        },
        onRefresh() {
         this.rRefreshing = true;
         this.rPageNumber = 1;
         this.rFinished = false;
         this.rIsNoData = false;
         this.getCommunityList('refresh');
        },onLoad(){
          this.rLoading = true;
          if (this.error) {
           this.error = false;
          } else {
           this.rPageNumber++
          }
          this.getCommunityList();
        },async getCommunityList(type) {
            let req = {
             pageNumber: this.rPageNumber,
             pageSize: this.rPageSize,
             tagID: this.tagID,
             sortType: this.tabActive === 0 ? 3 : 1,
             reqDate: new Date().toISOString(),
            }
            let res = await this.$Api(querySearchTagsVidList, req);

            this.rLoading = false;
            this.rRefreshing = false;
            this.rIsLoading = false;

            try {
                if(res && res.code == 200){

                    if(res.data.list){
                        if(type == "refresh"){
                            this.rCommunityList = res.data.list;
                        }else {
                            this.rCommunityList = this.rCommunityList.concat(res.data.list);
                        }
                    }

                    if (this.rPageNumber === 1 && this.rCommunityList.length === 0) {
                        this.rIsNoData = true;
                    }

                    if (!res.data.hasNext || (res.data.list && res.data.list.length < req.rPageSize)) {
                        this.rFinished = true;
                    }
                } else {
                    this.error = true;
                }
            } catch (error) {
                this.error = true;
                this.rLoading = false;
            }
        },async tabChange(item){
           this.rIsLoading = true;
           await this.getCommunityList('refresh');
        },async getHotspotWonderList() {
            /**
             * 获取最新列表
             */
            let req = {
              pageNumber: 1,
              pageSize: 12,
              tagType: 'isRecommend',
            }
            let res = await this.$Api(queryHotspotWonderList, req);

            if (res && res.code == 200) {
                this.hotspotWonderList = res.data.list;
            }

        },moreTopic(){
            /**
             * 更多话题（热门话题）
             */
            this.$router.push({
                path:"/hotTopic"
            })
        },releasePage(){
            /**
             * 发布作品
             */

            this.releaseShow = false;

            this.$router.push({
                path:"/releasePage"
            })
        },goAdv(item) {
            jumpAdv(item);
        },async focus(item) {
            /**
             * 关注/取消关注 话题
             */
            let req = {
                isCollect: !item.hasCollected,
                objID: this.tagID,
                type: "tag"
            }
            let res = await this.$Api(collectTag, req);

            if(res && res.code == 200){
                if(!item.hasCollected){
                    item.hasCollected = true;
                    this.$toast({
                        message: '关注成功',
                        position: 'top',
                    });
                }else{
                    item.hasCollected = false;
                    this.$toast({
                        message: '取消关注',
                        position: 'top',
                    });
                }
            }
        },
        jumpImg() {
         this.$store.commit('commnity/ADD_TAGS', [this.tagInfo]);
         this.$router.push("/publishImg")
            // if (this.userInfo.isVip) {
            //     this.$router.push("/publishImg")
            // } else {
            //     this.$bus.$emit("vipPopup", {
            //         // id: "",
            //         state: 1,
            //         // goldenNum: this.postInfo.coins,
            //         closeBtn: () => {
            //             this.$bus.$emit("closeVipPopup");
            //         }
            //     });
            // }
        },
        jumpVideo() {
         this.$store.commit('commnity/ADD_TAGS', [this.tagInfo]);
         this.$router.push("/publishVideo")
            // if (this.userInfo.isVip) {
            //     this.$router.push("/publishVideo")
            // } else {
            //     this.$bus.$emit("vipPopup", {
            //         // id: "",
            //         state: 1,
            //         // goldenNum: this.postInfo.coins,
            //         closeBtn: () => {
            //             this.$bus.$emit("closeVipPopup");
            //         }
            //     });
            // }
        },
    },watch:{
        async $route(to, from) {
            if (to.path == "/topicDetails") {
                if (to.query) {
                    if (this.tagID != to.query.id) {
                        this.tagID = to.query.id;
                        await this.getTagInfo();
                        this.rIsLoading = true;
                        await this.getCommunityList('refresh');
                    }
                }
            }
        }
    }
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/main.scss";
.community{
    width: 100%;
    height: 100%;
    background: rgba(245, 245, 245, 1);
    position: relative;
    ///deep/ .van-tabs__nav--line{
    //    width: 143px;
    //}
    //
    ///deep/ .van-tabs__wrap{
    //    //background: rgb(27,22,76);
    //    //border-bottom: solid 1px rgb(230,230,230);
    //}
    //
    ///deep/ .van-tabs--line .van-tabs__wrap{
    //    height: 40px;
    //}
    //
    ///deep/ .van-tab--active{
    //    font-size: 14px !important;
    //    font-weight: bold;
    //}
    //
    ///deep/ .van-tab{
    //    font-size: 12px;
    //}
    //
    ///deep/ .van-overlay{
    //    // background-color: rgba(0,0,0,0);
    //    z-index: 10;
    //}
    //
    ///deep/ .van-tabs__line{
    //    bottom: 20px
    //}

    .headBg{
        height: 150px;
        //background-image: linear-gradient(to bottom right, rgba(19,15,50,0), rgb(19,15,50));
        background: rgb(19,15,50);
        position: relative;
    }
    .headBg::before {
     content: '';
     position: absolute;
     left: 0;
     bottom: 0;
     width: 100%;
     height: 100%;
     background: linear-gradient(184.11deg, rgba(245, 245, 245, 0) 60%, #F5F5F5 88.46%);
     z-index: 1;
    }

    .tagContent{
        position: absolute;
        top: 100px;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% - 38px);
        height: 58px;
        color: rgb(0,0,0);
        padding: 16px 10px;
        background: #fff;
        border-radius: 20px;
        z-index: 2;

        .tagInfo{
            display: flex;

            .tagName{
                font-size: 18px;
                font-weight: bold;
                margin-right: 12px;
                color: #333333;
            }

        }

        .tagDesc{
            margin-top: 11px;
            font-size: 12px;
            color: rgba(102, 102, 102, 1);
        }
     .focusBtn{
      width: 63px;
      height: 26px;
      //font-size: 12px;
      //padding: 3px 10px;
      //background: rgb(113, 69, 231);
      //border-radius: 12.5px;
      //color: #94D6DA;
      //border: solid #94D6DA 1px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
       width: 63px;
       height: 26px;
      }
     }

     .alreadyFocus{
      width: 63px;
      height: 26px;
      //font-size: 12px;
      //padding: 3px 12px;
      //background: #B3B3B3;
      //border-radius: 12.5px;
      //color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
      //border: solid #94D6DA 2px;
      img {
       width: 63px;
       height: 26px;
      }
     }


    }

    .headBar{
        //border: solid 1px red;
        display: flex;
        height: 44px;
        width: calc(100% - 32px);
        padding: 0 16px;
        align-items: center;
        justify-content: space-between;
        //border-bottom: solid 1px rgb(230,230,230);
        position: absolute;
        z-index: 5;

        .leftIcon{
            //padding: 0 12px;
            .navArrow{
                width: 24px;
                height: 24px;
            }
        }

        .headTitle{
            color: rgb(255,255,255);
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 24px;
            //font-weight: bold;
            font-size: 18px;
        }


        .btnIcon{
            width: 12px;
            height: 12px;
            margin-right: 4px;
        }
    }
 /deep/ .van-overlay {
  background-color: rgba(0, 0, 0, 0.5);
 }
 .releaseBtns {
  display: flex;
  justify-content: flex-end;
  margin-top: 51px;
  //margin-right: 12px;
  position: fixed;
  bottom: 172px;
  right: 16px;

  .releaseBtnGroup {
   display: flex;
   flex-direction: column;
   align-items: center;
   //justify-content: center;
   font-size: 15px;
   color: rgb(255, 255, 255);

   .item {
    display: flex;
    align-items: center;
    color: rgba(176, 159, 217, 1);
    font-weight: bold;
    font-size: 14px;

    img {
     width: 40px;
     height: 40px;
     margin-top: 7px;
     //margin-right: 10px;
    }
   }

   .division {
    width: 84px;
    height: 1px;
    background: rgba(176, 159, 217, 1);
    margin: 6px 0;
   }
  }
 }

 .hotTopics {
  margin-top: 45px;
  padding: 16px 0;
  padding-left: 10px;

  .topicsHead {
   margin-top: 4px;
   color: rgba(51, 51, 51, 1);
   display: flex;
   justify-content: space-between;
   font-size: 18px;
   align-items: center;
   padding-right: 10px;

   .topicsTitle {
    font-weight: bold;
   }

   .more {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: rgba(119, 119, 119, 1);
   }
  }

  .topicsContent {
   width: 100%;
   display: flex;
   flex-wrap: nowrap;
   overflow-x: auto;
   margin-top: 12px;
   border-radius: 8px;
   background-size: 100% 100%;
   overscroll-behavior: contain;

   .topicsItem {
    margin-right: 12px;
    margin-left: 6px;
    //width: 50%;
    display: flex;
    flex-direction: column;
    //align-items: center;
    justify-content: space-between;
    position: relative;

    .portrait {
     width: 63px;
     height: 61px;
     border-radius: 14px;
     border: 3px solid rgba(255, 255, 255, 1);

     /deep/ .van-image__img {
      border-radius: 14px;
     }
    }

    .topicsInfo {
     font-size: 12px;
     z-index: 1;

     .topicsInfoTitle {
      color: #777777;
      font-size: 16px;
      font-weight: 600;
      text-align: center;
      //font-weight: bold;
      margin-top: 4px;
      white-space: nowrap;
     }
    }
   }
   .topicsItem:before {
    content: '';
    position: absolute;
    top: 12px;
    left: -7px;
    width: 80px;
    height: 85px;
    border-radius: 10px;
    background: linear-gradient(180deg, #FFFFFF 69.89%, rgba(255, 255, 255, 0) 100%);
   }
  }
 }

    .tabs{
        //height: calc(100vh - 51px - 86px - 120px);
        //border-bottom: solid 1px red;
        padding-top: 15px;
        @include cardTab();
        /deep/ .van-tabs__wrap {
         padding: 0 16px;
        }
        /deep/ .van-sticky--fixed{
         background: #f5f5f5;
         top: 0;
         height: 50px;
         padding: 0 10px;
         padding-top: 18px;
        }

        .tabContent{
            //height: 100%;
            //height: calc(100vh - 51px - 86px - 120px);

            .makeContent{
                //padding: 0 12px;
                //height: calc(100vh - 51px - 86px);

                .advertising{
                    padding: 0 12px;
                    /deep/ .vanImage {
                      border-radius: 8px;
                    }
                    /deep/ .van-image__img {
                      border-radius: 8px;
                    }
                    .swipe{
                        height: 140px;

                        .van-swipe-item{
                            color: #fff;
                            font-size: 20px;
                            line-height: 150px;
                            text-align: center;
                            background-color: rgb(43,39,72);
                        }

                        /deep/ .van-swipe__indicators{
                            left: auto;
                            right: 0;
                            align-items: center;
                        }
                        /deep/ .van-swipe__indicator{
                            width: 6px;
                            height: 6px;
                            background: rgba(255,255,255,.45);
                        }

                        /deep/ .van-swipe__indicator--active{
                            width: 9px;
                            height: 9px;
                        }
                    }
                }

                .hotTopics{
                    border-bottom: solid rgb(230,230,230) 1px;
                    padding: 12px 12px 30px 12px;

                    .topicsHead{
                        color: #000000;
                        display: flex;
                        justify-content: space-between;
                        font-size: 18px;
                        align-items: center;

                        .topicsTitle{
                            font-weight: bold;
                        }

                        .more{
                            font-size: 14px;
                        }

                        img{
                            width: 6.9px;
                            height: 8.6px;
                            margin-left: 6.1px;
                        }
                    }

                    .topicsContent{
                        display: flex;
                        flex-wrap: wrap;

                        .topicsItem{
                            margin-top: 12px;
                            width: 50%;
                            display: flex;
                            align-items: center;
                            //justify-content: space-between;

                            .portrait{
                                width: 42px;
                                height: 42px;
                                border-radius: 21px;
                                background: rgb(230,230,230);
                            }

                            .topicsInfo{
                                margin-left: 6px;
                                font-size: 12px;
                                width: 90px;

                                .topicsInfoTitle{
                                    color: rgb(0,0,0);
                                    font-weight: bold;
                                }

                                .topicsInfoContent{
                                    color: rgb(153,153,153);
                                    overflow: hidden; //超出的文本隐藏
                                    text-overflow: ellipsis; //溢出用省略号显示
                                    white-space: nowrap;  // 默认不换行；
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .editorBtn{
        position: absolute;
        top: 13px;
        right: 12px;
        //right: 12px;
        //padding-top: 12px;
        //background: rgb(27,22,76);

        img{
            width: 18px;
            height: 19px;
        }
    }

    .communityList{
        //margin-top: 30px;


    }
}

.publishBtn {
    position: fixed;
    right: 8px;
    bottom: 34px;
    width: 54px;
    height: 54px;
    background: url("../../assets/png/publishIcon.png") center no-repeat;
    background-size: 100% 100%;
    z-index: 2222;
}
.publishPop {
    .publishImg {
        position: absolute;
        right: 12px;
        bottom: 244px;
        width: 50px;
        height: 50px;
        background: url("../../assets/png/imgIcon.png") center no-repeat;
        background-size: 100% 100%;
    }
    .publishVideo {
        position: absolute;
        right: 12px;
        bottom: 182px;
        width: 50px;
        height: 50px;
        background: url("../../assets/png/videoIcon.png") center no-repeat;
        background-size: 100% 100%;
    }
}
</style>
